<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div
        class="
          flex
          justify-between
          items-center
          mt-5
          mb-4
          border-gray-300 border-b
        "
      >
        <h1 class="text-xl mt-3 mb-5">{{ $t("time_out_overview") }}</h1>
        <t-button
          class="text-sm rounded-md mr-2"
          variant="primary"
          @click="
            resetForm();
            modal = true;
          "
        >
          {{ $t("add_time_out") }}
        </t-button>
      </div>
      <div class="overflow-x-auto mb-5">
        <t-table :headers="confirmedHeader" :data="confirmed">
          <template slot="row" slot-scope="props">
            <tr>
              <td :class="props.tdClass">
                {{ props.row.from | date }} - {{ props.row.until | date }}
              </td>
              <td :class="props.tdClass">{{ props.row.admin_name }}</td>
              <td :class="props.tdClass">{{ props.row.user_name }}</td>
              <td :class="props.tdClass">{{ props.row.user_jobs }}</td>
              <td class="w-1/3" :class="props.tdClass">
                {{ props.row.comment }}
              </td>
              <td :class="props.tdClass">{{ props.row.updated_at | date }}</td>
              <td class="w-24" :class="props.tdClass">
                <template>
                  <div
                    class="
                      grid grid-flow-col
                      auto-cols-max
                      gap-4
                      justify-center
                    "
                  >
                    <t-button
                      v-if="isEditable(props.row)"
                      class="text-sm rounded-md"
                      @click="edit(props.row)"
                    >
                      {{ $t("update") }}
                    </t-button>

                    <t-button
                      v-if="isTodayOrGreater(props.row.until)"
                      class="text-sm rounded-md"
                      @click="disapprove(props.row.id)"
                    >
                      {{ $t("disapprove") }}
                    </t-button>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <t-button
        class="text-sm mt-3"
        variant="primary"
        :disabled="loading"
        @click="getOlderConfirmed"
      >
        {{ $t("show_older_timeouts") }}
      </t-button>
      <t-modal v-model="modal" @closed="resetForm">
        <div class="mb-3 px-2">
          <label>{{ $t("edit_user") }}</label>
          <t-select
            v-model="form.user_id"
            :placeholder="$t('timeout_overview_select_user_placeholder')"
            :disabled="!!id"
            class="w-full"
            :options="users"
            :variant="error.user_id ? 'danger' : ''"
          ></t-select>
        </div>
        <div class="flex flex-nowrap mb-3">
          <div class="px-2">
            <label>{{ $t("from") | capitalize }}</label>
            <t-datepicker
              v-model="form.from"
              :variant="error.from ? 'danger' : ''"
            />
          </div>
          <div class="px-2">
            <label>{{ $t("until") | capitalize }}</label>
            <t-datepicker
              v-model="form.until"
              :variant="error.until ? 'danger' : ''"
            />
          </div>
        </div>
        <div class="mb-3 px-2">
          <label>{{ $t("comment") }}</label>
          <t-textarea
            class="w-full"
            rows="3"
            v-model="form.comment"
            :variant="error.comment ? 'danger' : ''"
          />
        </div>
        <template v-slot:footer>
          <div class="flex justify-end">
            <t-button
              class="text-sm"
              variant="primary"
              :disabled="loading"
              @click="submit()"
            >
              {{ $t("edit_save") }}
            </t-button>
          </div>
        </template>
      </t-modal>
      <t-dialog icon="question" type="confirm"></t-dialog>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { get, map, pick } from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    form: {
      user_id: null,
      from: moment().format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
      comment: null,
    },
    error: {
      user_id: false,
      from: false,
      until: false,
      comment: false,
    },
    id: null,
    modal: false,
    loading: false,
    showLastYear: false,
  }),
  methods: {
    ...mapActions({
      getConfirmedHolidays: actions.HOLIDAYS_FETCH_CONFIRMED_ACTION,
      createUserHoliday: actions.HOLIDAYS_CREATE_USER_HOLIDAY_ACTION,
      updateUserHoliday: actions.HOLIDAYS_UPDATE_USER_HOLIDAY_ACTION,
      approveHoliday: actions.HOLIDAYS_UPDATE_APPROVED_ACTION,
      removeHoliday: actions.HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION,
    }),
    async submit() {
      this.error.user_id = !this.form.user_id;
      this.error.from = !this.form.from;
      this.error.until = !this.form.until;
      this.error.comment = !this.form.comment;

      if (!Object.keys(this.error).every((k) => !this.error[k])) {
        return;
      }

      const { isOk } = await this.$dialog.confirm(
        "Save user holiday",
        `Are you sure?`,
        "info"
      );

      if (!isOk) {
        return;
      }

      try {
        if (this.id) {
          await this.updateUserHoliday({
            id: this.id,
            payload: this.form,
          });
        } else {
          await this.createUserHoliday({
            ...this.form,
            horeca_location_id: this.locationId,
            admin_user_id: this.loggedUser.user.id,
            approved: true,
          });
        }

        this.id = null;
        this.modal = false;
      } catch (error) {
        console.error("DEBUG: submit", error);
      }

      try {
        await Promise.all([
          this.getConfirmedHolidays({
            showLastYear: Number(this.showLastYear),
            locationId: this.locationId,
          }),
        ]);
      } catch (error) {
        console.error(
          [
            actions.HOLIDAYS_FETCH_UNAPPROVED_ACTION,
            actions.HOLIDAYS_FETCH_CONFIRMED_ACTION,
          ],
          error
        );
      }
    },
    async getOlderConfirmed() {
      try {
        this.loading = true;
        this.showLastYear = true;
        await this.getConfirmedHolidays({
          showLastYear: Number(this.showLastYear),
        });
      } catch (error) {
        console.error(actions.HOLIDAYS_FETCH_CONFIRMED_ACTION, error);
      }

      this.loading = false;
    },
    async approve(id) {
      try {
        this.loading = true;
        await this.approveHoliday({ id, approved: true });
      } catch (error) {
        console.error(actions.HOLIDAYS_UPDATE_APPROVED_ACTION, error);
      }

      this.loading = false;

      try {
        await this.getConfirmedHolidays({
          showLastYear: Number(this.showLastYear),
          locationId: this.locationId,
        });
      } catch (error) {
        console.error(actions.HOLIDAYS_FETCH_CONFIRMED_ACTION, error);
      }
    },
    async disapprove(id) {
      try {
        this.loading = true;
        await this.removeHoliday({ id, sendMail: 1 });
      } catch (error) {
        console.error(actions.HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION, error);
      }

      this.loading = false;
    },
    async edit(holiday) {
      this.form = pick(holiday, ["user_id", "from", "until", "comment"]);
      this.id = get(holiday, "id");
      this.modal = true;
    },
    isTodayOrGreater(date) {
      return moment(date).isSameOrAfter(moment(), "day");
    },
    isEditable(holiday) {
      const today = moment().startOf("day");
      const ongoing = today.isBetween(
        moment(holiday.from),
        moment(holiday.until),
        "day",
        "[]"
      );
      const isFuture =
        moment(holiday.from).isAfter(today) &&
        moment(holiday.until).isAfter(today);

      return ongoing || isFuture;
    },
    resetForm() {
      this.id = null;
      this.form = {
        user_id: null,
        comment: null,
        from: moment().format("YYYY-MM-DD"),
        until: moment().format("YYYY-MM-DD"),
      };
    },
  },
  computed: {
    ...mapGetters({
      unapproved: getters.HOLIDAYS_UNAPPROVED_GETTER,
      confirmed: getters.HOLIDAYS_CONFIRMED_GETTER,
      enabledUsers: getters.USERS_ENABLED_GETTER,
      loggedUser: getters.AUTH_USER_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    users() {
      return map(this.enabledUsers, (user) => ({
        label: user.NAME,
        value: user.ID,
      }));
    },
    unapprovedHeader() {
      return [
        {
          value: "date",
          text: this.$i18n.t("date"),
        },
        {
          value: "name",
          text: this.$i18n.t("name"),
        },
        {
          value: "comment",
          text: this.$i18n.t("comment"),
        },
        {
          value: "created",
          text: this.$i18n.t("created"),
        },
        {},
      ];
    },
    confirmedHeader() {
      return [
        {
          value: "date",
          text: this.$i18n.t("date"),
        },
        {
          value: "admin",
          text: this.$i18n.t("admin"),
        },
        {
          value: "name",
          text: this.$i18n.t("name"),
        },
        {
          value: "job",
          text: this.$i18n.t("job"),
        },
        {
          value: "comment",
          text: this.$i18n.t("comment"),
        },
        {
          value: "created",
          text: this.$i18n.t("created"),
        },
        {},
      ];
    },
  },
  watch: {
    form: {
      handler() {
        Object.keys(this.error).forEach((key) => {
          this.error[key] = false;
        });
      },
      deep: true,
    },
  },
  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    capitalize(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
